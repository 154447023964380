import 'mapbox-gl/dist/mapbox-gl.css';

import './css/main.css';

import barba from '@barba/core';

import { gsap } from "gsap";

import { defineCustomElements } from 'vidstack/elements';


barba.init({
    debug: true,
    timeout: 7000,
    transitions: [
      {
      name: 'opacity-transition',
      leave(data) {
        return gsap.to(data.current.container, {
          opacity: 0
        });
      },
      enter(data) {
        
      },
      after(data) {
        return gsap.from(data.next.container, {
            opacity: 0
          });
        
        
      },
      once(data) {
        galleryCheck();
        setTimeout(function(){defineCustomElements();vidCheck();}, 200);
        jsJump();
      },
      afterOnce(data) {
        
      }
    }],
});

function vidCheck() {
  let vidPlayers = document.querySelectorAll('media-player');
  if (vidPlayers.length > 0) {
      let vd;
      for (vd=0; vd < vidPlayers.length; vd++) {
          vidPlayers[vd].addEventListener('provider-change', (event) => {
              const provider = event.detail;
              if (provider?.type === 'hls') {
                  console.log('HLS config change');
                  provider.config = { startLevel: 7 };
              };
          });


          vidPlayers[vd].addEventListener('hls-level-switching', (event) => {
            // console.log(event.detail)
          });
      };
  };
};

function galleryCheck() {
  let galleries = document.getElementsByClassName('gallery');
  if (galleries.length > 0) {
    import(/* webpackChunkName: "gallery" */ "./js/components/gallery.js").then(module => {
      const galleryGo = module.default;
      galleryGo();
  });
};
};

function scrollToTop() {
  // window.scrollTo({top: 0, behavior: 'smooth'});
  history.scrollRestoration = 'manual';
  window.scrollTo({top: 0, behavior: 'auto'});
  
  // console.log('Scroll to top');
};

function jsJump() {
  let jumps = document.getElementsByClassName('jumpIt');
  if (jumps.length > 0) {
    let jp;
    for (jp=0; jp < jumps.length; jp++) {
      jumps[jp].addEventListener('click', function () {
          let mainUrl = event.target.getAttribute('data-jumpUrl');
          
          barba.go(mainUrl, event.target);
          barba.hooks.after((data) => {
            // console.log(data.trigger);
            let jumpItem = data.trigger.getAttribute('data-jumpItem');
            let jumpDiv = document.getElementById(jumpItem);
            // console.log(jumpDiv);
            jumpDiv.scrollIntoView();
          })
      });
    }
  }
}

barba.hooks.after((data) => {
  scrollToTop();
});


barba.hooks.after((data) => {
  setTimeout(function(){defineCustomElements();vidCheck();}, 200);
  galleryCheck();
  jsJump();
});

